import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'maz-selling-point-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShellComponent {}

@NgModule({
  imports: [CommonModule, MatToolbarModule, RouterModule],
  declarations: [ShellComponent],
  exports: [ShellComponent],
})
export class ShellComponentModule {}
