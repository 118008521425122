import { isPlatformServer } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, PLATFORM_ID, TransferState, inject, makeStateKey } from '@angular/core';
import { REQUIRES_AUTH, UPDATES_SESSION } from '@mazboletos/shared/core/http/interceptors';
import { REQUEST } from '@mazboletos/shared/core/http/tokens';
import { Observable, tap } from 'rxjs';

@Injectable()
export class AnonymousSessionInterceptor implements HttpInterceptor {
  private transferState = inject(TransferState);
  private platformId = inject(PLATFORM_ID);
  private request = inject(REQUEST, { optional: true });

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.context.has(REQUIRES_AUTH)) {
      let token: string | null | undefined;
      if (isPlatformServer(this.platformId)) {
        token = this.request?.headers.cookie?.split('_token=')[1]?.split(';')[0];
      } else {
        token = localStorage.getItem('_token') ?? this.transferState.get(makeStateKey<string>('_token'), null);
      }
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    }
    return next.handle(request).pipe(
      tap((event: any) => {
        if (request.context.has(UPDATES_SESSION) && event instanceof HttpResponse) {
          const token = event.body?.data.token;
          if (token) {
            if (isPlatformServer(this.platformId)) {
              this.transferState.set(makeStateKey<string>('_token'), token);
            } else {
              localStorage.setItem('_token', token);
            }
          }
        }
      })
    );
  }
}
