import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class QueryParamsHandlerInterceptorInterceptor implements HttpInterceptor {
  #route = inject(ActivatedRoute);
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const queryParams = this.#route.snapshot.queryParams;
    let modifiedRequest = request;

    if (Object.keys(queryParams).length > 0) {
      let modifiedParams = request.params;

      for (const key in queryParams) {
        modifiedParams = modifiedParams.append(key, queryParams[key]);
      }

      modifiedRequest = request.clone({
        params: modifiedParams,
      });
    }

    return next.handle(modifiedRequest);
  }
}
