import { provideImageKitLoader } from '@angular/common';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { APP_CONFIG } from '@mazboletos/selling-point/core/config';
import { SellingPointShellFeatureModule } from '@mazboletos/selling-point/shell/feature';
import { FALLBACK_ROUTE } from '@mazboletos/shared/core/fallback-route';
import { API_URL, JsonApiInterceptor } from '@mazboletos/shared/core/http/interceptors';
import { RouteUtils } from '@mazboletos/shared/utils/route';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { provideEnvironmentNgxMask } from 'ngx-mask';

import {
  NUMBER_OF_SEATS_SELECTED_PER_TABLE,
  SVG_SEAT_CLASS,
  SVG_SEAT_COURTESY_CLASS,
  SVG_SEAT_ORPHAN_CLASS,
  SVG_SEAT_RESALE_CLASS,
  SVG_SEAT_SELECTED_CLASS,
  SVG_TABLE_SELECTOR,
  SVG_UNAVAILABLE_CLASS,
  VENUE_CONTEXT,
} from '@mazboletos/shared/ui/seat-selection/tokens';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { QueryParamsHandlerInterceptorInterceptor } from '@mazboletos/shared/core/http/query-params-handler-interceptor';
import { AddSeatsManagerQueryInterceptor } from '@mazboletos/selling-point/seats-manager/data-access/add-seats-manager-query';
import { AnonymousSessionInterceptor } from '@mazboletos/shared/core/http/anonymous-session-interceptor';
import { AuthInterceptor } from '@mazboletos/auth-interceptor';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SellingPointShellFeatureModule,
    StoreModule.forRoot(
      {},
      {
        metaReducers: !environment.production ? [] : [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    StoreRouterConnectingModule.forRoot(),
  ],
  bootstrap: [AppComponent],
  providers: [
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    provideEnvironmentNgxMask(),
    provideImageKitLoader(environment.imageKitUrl),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: AuthInterceptor,
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: JsonApiInterceptor,
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: QueryParamsHandlerInterceptorInterceptor,
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: AddSeatsManagerQueryInterceptor,
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: AnonymousSessionInterceptor,
    },
    {
      provide: APP_CONFIG,
      useValue: environment,
    },
    {
      provide: API_URL,
      useValue: environment.apiUrl,
    },
    {
      provide: FALLBACK_ROUTE,
      useValue: RouteUtils.Dashboard,
    },
    {
      provide: SVG_SEAT_CLASS,
      useValue: 's',
    },
    {
      provide: SVG_SEAT_COURTESY_CLASS,
      useValue: 'c',
    },
    {
      provide: SVG_TABLE_SELECTOR,
      useValue: 'MESAS',
    },
    {
      provide: SVG_SEAT_SELECTED_CLASS,
      useValue: 'selected',
    },
    {
      provide: SVG_SEAT_ORPHAN_CLASS,
      useValue: 'warn',
    },
    {
      provide: SVG_UNAVAILABLE_CLASS,
      useValue: 'u',
    },
    {
      provide: SVG_SEAT_RESALE_CLASS,
      useValue: 'resale',
    },
    {
      provide: NUMBER_OF_SEATS_SELECTED_PER_TABLE,
      useValue: 2,
    },
    {
      provide: VENUE_CONTEXT,
      useValue: 'panel_venue_handling',
    },
    provideClientHydration(),
  ],
})
export class AppModule {}
