import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AddSeatsManagerQueryInterceptor implements HttpInterceptor {
  private router = inject(Router);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const url = this.router.routerState.snapshot.url;

    if (url.includes('gestor')) {
      request = request.clone({
        setParams: {
          view: 'seats-manager',
        },
      });
    }

    return next.handle(request);
  }
}
